import { ContentfulSectionFieldsFragmentFragment } from '@/graphql/contentful'

export function transformTextListValue<
  T extends string | ContentfulSectionFieldsFragmentFragment['textlistValue']
>(textlistValue?: T | null | undefined) {
  if (textlistValue) {
    return Object.entries(textlistValue)
      .map(([, v]) => v)
      .join('')
  }
}
