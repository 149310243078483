import { useDatahappyValue } from '@/hooks/useDatahappy'
import { KlaviyoProfile } from '@heights/heights-types'

export function klaviyoSubscribeHandlerFactory(listId: string) {
  return async (profiles: KlaviyoProfile[]) => {
    const email = profiles.find((p) => p.$consent === 'email') as
      | Extract<KlaviyoProfile, { $consent: 'email' }>
      | undefined
    const phone = profiles.find((p) => p.$consent === 'sms') as
      | Extract<KlaviyoProfile, { $consent: 'sms' }>
      | undefined
    window.klaviyo?.identify({
      ...((email?.first_name || phone?.first_name) && {
        $first_name: email?.first_name || phone?.first_name,
      }),
      ...(email?.email && { $email: email.email }),
      ...(phone?.phone_number && { $phone_number: phone.phone_number }),
    })
    useDatahappyValue[0].trackLeadCreation({
      userTraits: {
        firstName: email?.first_name ?? phone?.first_name,
        email: email?.email,
        phone: phone?.phone_number,
      },
    })
    return new Promise<{
      success: boolean
    }>((resolve, reject) => {
      fetch('/api/klaviyo/subscribe', {
        method: 'POST',
        headers: new Headers([['Content-Type', 'application/json']]),
        body: JSON.stringify({
          listId: listId,
          profiles,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return resolve(response.json())
          } else {
            const error = new Error(response.statusText) as Error & {
              response: typeof response
            }
            error.response = response
            return reject(error)
          }
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}
