import React, {
  FC,
  ComponentProps,
  useState,
  useContext,
  useCallback,
} from 'react'
import * as Sentry from '@sentry/nextjs'
import { SubscribeSuccessLarge } from '@heights/heights-ui-library'
import { NewsletterSubscribeBanner } from '@heights/heights-ui-components'
import { RichTextContent } from '@/components/RichTextContent'
import { SubscribeFormContext } from '@/context'
import {
  dropSubscribeCookie,
  klaviyoSubscribeHandlerFactory,
  transformTextListValue,
  withErrorBoundary,
} from '@/utils'
import { textItem as textItemSchema } from '@/schemas/textItem'
import { sectionDataHookFactory } from '@/hooks'
import type { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { asResolverQuery } from '@/utils/groq'

type NewsletterSubscribeBannerProps = ComponentProps<
  typeof NewsletterSubscribeBanner
>

type Props = {
  data: SingleComponentPickerItem
}

const [textItemQuery, textItemType] = textItemSchema.builder
  .map((item) => ({
    image: item.fields.image?.resolve(['fields']).use(),
  }))
  .use()

const useSectionData = sectionDataHookFactory({
  textItems: asResolverQuery<typeof textItemType>(
    'fields.components',
    textItemQuery,
    '@->_type == "textItem"'
  ),
})

export const ContentfulSectionNewsletterSubscribeBanner: FC<Props> =
  withErrorBoundary(({ data }) => {
    const subscribeFormContext = useContext(SubscribeFormContext)
    const [error, setError] = useState<string | null>(null)
    const section = useSectionData(data._id)
    const textItemChild = section?.textItems?.[0]

    const handleSubmit = useCallback<
      NonNullable<NewsletterSubscribeBannerProps['onSubmit']>
    >(
      async (values) => {
        try {
          if (values) {
            setError(null)

            const response = await klaviyoSubscribeHandlerFactory(
              transformTextListValue(section?.fields?.textlistValue?.[0]) ??
                'RndhXp'
            )([
              {
                email: values.email,
                $consent: 'email',
              },
            ])

            const body = response as {
              success: boolean
            }
            subscribeFormContext.setSuccess(body.success)
            dropSubscribeCookie(
              'subscribed',
              90,
              subscribeFormContext.cookieName
            )
          }
        } catch (err) {
          Sentry.captureException(err)
          subscribeFormContext.setSuccess(false)
          setError(err instanceof Error ? err.message : String(err))
        }
      },
      [section?.fields?.textlistValue, subscribeFormContext]
    )

    if (!section) {
      return null
    }

    return (
      <NewsletterSubscribeBanner
        id={section.fields?.trackingId ?? ''}
        showSuccessState={subscribeFormContext.success}
        error={error}
        successState={
          textItemChild ? (
            <SubscribeSuccessLarge
              variant="simple"
              title={textItemChild.fields?.title ?? ''}
              successMessage={
                textItemChild.fields?.content ? (
                  <RichTextContent
                    inheritParagraphFont
                    document={textItemChild.fields?.content}
                  />
                ) : null
              }
            />
          ) : null
        }
        disclaimer={section.fields?.subtitle}
        onSubmit={handleSubmit}
      >
        {!!section.fields?.description && (
          <RichTextContent
            inheritParagraphFont
            document={section.fields?.description}
          />
        )}
      </NewsletterSubscribeBanner>
    )
  })

export default ContentfulSectionNewsletterSubscribeBanner
